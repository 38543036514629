<template>
  <div class="editViews">
    <div style="margin-bottom: 2.778vw">
      <NavBar ref="navbar"></NavBar>
    </div>
    <div class="uploadfile">
      <div class="uploadimg">
        <van-uploader
          preview-size="100vw"
          :after-read="afterRead"
          @oversize="onOversize"
          :max-size="isOverSize"
          name="file"
        />
      </div>
      <edit-banner left="头像">
        <van-image
          round
          width="13.889vw"
          height="13.889vw"
          :src="userInfo.pic"
        />
      </edit-banner>
    </div>

    <edit-banner
      left="个性签名"
      @bannerClick="textfieldClick"
      field="content"
      msg="不能什么都不写"
    >
      <p slot="right">{{ userInfo.content }}</p>
    </edit-banner>

    <edit-banner
      field="name"
      rule="^.{1,16}$"
      msg="用户名在1-16位"
      left="用户名"
      @bannerClick="fieldClick"
    >
      <a href="javascript:;" slot="right">{{ userInfo.name }}</a>
    </edit-banner>

    <edit-banner
      field="phone"
      rule="/^1(?:3\d|4[4-9]|5[0-35-9]|6[67]|7[013-8]|8\d|9\d)\d{8}$/"
      msg="不是正确电话号码"
      left="电话"
      @bannerClick="fieldClick"
    >
      <a href="javascript:;" slot="right">{{ userInfo.phone }}</a>
    </edit-banner>

    <edit-banner field="sex" left="性别" @bannerClick="listClick">
      <a href="javascript:;" slot="right">{{ userInfo.sex ? "男" : "女" }}</a>
    </edit-banner>

    <edit-banner
      field="education"
      left="学历"
      @bannerClick="listClick"
      :key="userInfo.education"
    >
      <a href="javascript:;" slot="right">{{ educationTxt }}</a>
    </edit-banner>
    <edit-banner
      field="major"
      rule="^.{1,16}$"
      msg="专业不能为空"
      left="专业"
      @bannerClick="fieldClick"
    >
      <a href="javascript:;" slot="right">{{ userInfo.major }}</a>
    </edit-banner>

    <edit-banner
      left="E-mail"
      field="email"
      rule="\w[-\w.+]*@([A-Za-z0-9][-A-Za-z0-9]+\.)+[A-Za-z]{2,14}"
      msg="邮箱格式不正确"
      @bannerClick="fieldClick"
    >
      <a href="javascript:;" slot="right">{{ userInfo.email }}</a>
    </edit-banner>
    <edit-banner
      field="qq"
      rule="[1-9]([0-9]{5,11})"
      msg="QQ号码不正确"
      left="qq"
      @bannerClick="fieldClick"
    >
      <a href="javascript:;" slot="right">{{ userInfo.qq }}</a>
    </edit-banner>

    <div class="editback" @click="$router.back()">返回个人中心</div>
    <!-- 字符串型 -->
    <van-dialog
      v-model="show"
      :title="this.title"
      show-cancel-button
      @confirm="confirmClick"
      @cancel="content = ''"
    >
      <van-field
        v-model="content"
        autofocus
        left-icon="arrow"
        placeholder="请输入修改信息"
      />
    </van-dialog>

    <!-- 列表 -->
    <van-action-sheet
      v-model="listshow"
      :title="this.title"
      cancel-text="取消"
      :actions="actions"
      @select="onSelect"
      color="green"
    />

    <!-- 多行 -->
    <van-dialog
      v-model="textshow"
      title="个签"
      show-cancel-button
      @confirm="textareaClick"
      @cancel="content = ''"
    >
      <van-field
        v-model="content"
        type="textarea"
        autofocus
        left-icon="arrow"
        placeholder="请输入个性签名"
        maxlength="200"
        show-word-limit
      />
    </van-dialog>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'
import { areaList } from '@vant/area-data'
import NavBar from '@/components/common/Navbar.vue'
import editBanner from '@/components/common/editBanner.vue'

const api = new Vue()

export default {
  data () {
    return {
      model: {},
      check: {},
      show: false,
      textshow: false,
      listshow: false,
      birthdayshow: false,
      addressshow: false,
      content: '',
      title: '',
      placeholder: '',
      actions: [],
      sex: [
        { name: '男', val: 1 },
        { name: '女', val: 0 }
      ],
      education: [
        { name: '博士及以上', val: 6 },
        { name: '硕士研究生', val: 5 },
        { name: '大学本科', val: 4 },
        { name: '大学专科', val: 3 },
        { name: '高中及中专', val: 2 },
        { name: '初中及以下', val: 1 }
      ],
      minDate: new Date(1978, 1, 1),
      maxDate: new Date(2050, 10, 1),
      currentDate: new Date(this.year, this.month),
      areaList: areaList
    }
  },
  components: {
    NavBar,
    editBanner
  },
  beforeMount () {
    this.$store.commit('hideShowTabbar', false)
  },
  computed: {
    ...mapState(['userInfo']),
    educationTxt () {
      console.log(this.$store.state.userInfo)
      if (this.$store.state.userInfo.education === '6' || this.$store.state.userInfo.education === 6) {
        return '博士及以上'
      } else if (this.$store.state.userInfo.education === '5' || this.$store.state.userInfo.education === 5) {
        return '硕士研究生'
      } else if (this.$store.state.userInfo.education === '4' || this.$store.state.userInfo.education === 4) {
        return '大学本科'
      } else if (this.$store.state.userInfo.education === '3' || this.$store.state.userInfo.education === 3) {
        return '大学专科'
      } else if (this.$store.state.userInfo.education === '2' || this.$store.state.userInfo.education === 2) {
        return '高中及中专'
      } else if (this.$store.state.userInfo.education === '1' || this.$store.state.userInfo.education === 1) {
        return '初中及以下'
      } else return '-'
    }

  },

  mounted () {
    if (this.$store.state.userInfo.length === 0) {
      this.$store.dispatch('getUserInfoAction', {
        uid: localStorage.getItem('uid')
      })
    }
  },
  methods: {
    // 文件上传
    async afterRead (file, name) {
      console.log(file, name, 'file')
      const fromdata = new FormData()
      fromdata.append('upload', file.file)
      console.log(fromdata, 'fromdata')
      console.log(api.$api, 'api')
      const res = await api.$api.upload.FileUpload(fromdata)

      console.log(res, 'res')
      //   this.userInfo.pic = res.data.url
      //   this.UserUpdate()
      //   this.$refs.navbar.NavInit()
    },
    async UserUpdate () {
      //   console.log(this.model)
      this.model.uid = localStorage.getItem('uid')
      const res = await api.$api.user.UserUpdate(localStorage.getItem('uid'), this.model)
      if (res.data.code === 200) {
        // this.$store.dispatch('updateUserInfoAction', this.model)
        this.$store.commit('updateUserInfoMutation', this.model)
        api.$msg('修改成功')
      } else if (res.data.code === 10001) {
        api.$msg(res.data.msg)
      }
    },
    // 字符串型
    fieldClick (data) {
      //   console.log(data)
      this.model.field = data.field
      this.check.rule = data.rule
      this.check.msg = data.msg
      this.title = data.title
      this.show = true
    },
    confirmClick () {
      const regs = new RegExp(this.check.rule)
      if (regs.test(this.content)) {
        this.model.value = this.content
        // console.log(this.model)
        this.UserUpdate()
        this.content = ''
      } else {
        api.$msg(this.check.msg)
      }
    },

    // 列表
    listClick (data) {
      //   console.log(data)
      this.model.field = data.field
      this.title = data.title
      if (this.model.field === 'sex') {
        this.actions = this.sex
      } else if (this.model.field === 'education') {
        this.actions = this.education
      }
      this.listshow = true
    },
    onSelect (data) {
      this.model.value = data.val
      //   console.log(this.model)
      this.listhidden()
    },
    listhidden () {
      this.UserUpdate()
      this.listshow = false
    },

    // 多行
    textfieldClick (data) {
      //   console.log(data)
      this.model.field = data.field
      this.check.rule = data.rule
      this.check.msg = data.msg
      this.title = data.title
      this.textshow = true
    },
    textareaClick () {
      //   console.log(this.content)
      if (this.content !== '') {
        this.model.value = this.content.replace(/\n/g, ',')
        console.log(this.model)
        this.UserUpdate()
        this.content = ''
      } else {
        api.$msg('多少写点什么吧')
      }
    },

    // 文件上传
    onOversize (file) {
      //   const PicUploadSize = localStorage.getItem('PicUploadSize')
      // https://www.jb51.net/article/178528.htm
      console.log(file)
      api.$msg('文件大小不能超过 500kb')
    },
    isOverSize (file) {
      const maxSize = file.type === 'image/jpeg' ? 500 * 1024 : 1000 * 1024
      return file.size >= maxSize
    }
  },
  created () {
    // this.selectUser()
  },
  beforeUnmount () {
    this.$store.commit('hideShowTabbar', true)
  }
}
</script>

<style lang="scss" scoped>
.editViews a {
  color: #333;
}
.editViews img {
  height: 12.778vw;
  width: 12.778vw;
  border-radius: 50%;
}
.uploadfile {
  overflow: hidden;
  position: relative;
  .uploadimg {
    opacity: 0;
    position: absolute;
  }
}
.editback {
  margin-top: 5.556vw;
  width: 100%;
  background-color: #26262a;
  //   display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  color: #f7ce7c;
  padding: 4.167vw 0;
  font-size: 4vw;
  position: fixed;
  bottom: 0;
}
</style>
